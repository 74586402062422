import React, {useEffect, useState} from "react";
import {Switch, Route, Link, BrowserRouter, useHistory, Redirect} from "react-router-dom";

import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";
import Login from "./components/Authentication/Login";
import Search from "./components/Search/Search";
import Home from "./components/Home";
import Profile from "./components/Profile";
import Edit from "./components/Edit/Edit";
import BoardModerator from "./components/BoardModerator";
import BoardAdmin from "./components/BoardAdmin";
import NavBarGroup from "./components/NavBar";
import UploadFiles from "./components/Upload/UploadFiles";
import NewNode from "./components/Edit/NewNode";
import ManageUsers from "./components/Management/ManageUsers";
import ResyncIndices from "./components/Management/ResyncIndices";
import ImageFunctions from "./components/Management/imageFunctions";
import Footer from "./components/Footer";
import AuthService from "./services/auth.service";
import GetUpdate from "./components/Management/GetUpdate";
import {Helmet} from "react-helmet";
import favicon from "./components/Icons/favicon/favicon.ico"
import CookiePolicy from "./components/Cookie-policy/CookiePolicy";
import {Box, FooterContainter} from "./components/FooterStyles";
import PrivacyPolicy from "./components/Privicy-policy/PrivacyPolicy";
import GeneralTerms from "./components/General-terms/GeneralTerms";

const App = () => {

    const [currentUser, setCurrentUser] = useState(undefined);

    useEffect(() => {
        setCurrentUser(AuthService.getCurrentUser());
    }, []);

    const [locationKeys, setLocationKeys] = useState([])
    const history = useHistory()

    useEffect(() => {
        history.listen(location => {
            console.log("history.action = " + history.action)
            console.log("location.key = " + location.key)
            if (location.key != undefined) {
                console.log("Nu terug")
                window.location.reload();
            }

        })
    }, []);

    return (
        <BrowserRouter basename="/">
            <Helmet>
                <title>Datamachine Workbench</title>
                <link rel="icon" type="image/png" href={favicon} sizes="16x16"/>
            </Helmet>
            <div className="App">
                    {currentUser &&
                        <div>
                            <NavBarGroup/>
                            <Switch>
                                <Route exact path="/" component={Home}/>
                                <Route exact path="/home" component={Home}/>
                                <Route exact path="/login" component={Login}/>
                                <Route exact path="/profile" component={Profile}/>
                                <Route path="/search" component={Search}/>
                                <Route path="/edit" component={Edit}/>
                                <Route path="/edit/:id" component={Edit}/>
                                <Route path="/mod" component={BoardModerator}/>
                                <Route path="/admin" component={BoardAdmin}/>
                                <Route path="/upload" component={UploadFiles}/>
                                <Route path="/new" component={NewNode}/>
                                <Route path="/manageUsers" component={ManageUsers}/>
                                <Route path="/getUpdate" component={GetUpdate}/>
                                <Route path="/resyncIndices" component={ResyncIndices}/>
                                <Route path="/matchImagesOffline" component={ImageFunctions}/>
                                <Route path="/CookiePolicy" component={CookiePolicy}/>
                                <Redirect to="/"/>
                            </Switch>
                        </div>

                    }
                    {!currentUser &&
                        <Box>
                            <NavBarGroup/>
                            <Switch>
                                <Route exact path="/login" component={Login}/>
                                <Route path="/GeneralTerms" component={GeneralTerms}/>
                                <Route path="/CookiePolicy" component={CookiePolicy}/>
                                <Route path="/PrivacyPolicy" component={PrivacyPolicy}/>
                                {/*<Route path="/" component={Home}/>*/}
                            </Switch>
                            <Footer/>
                        </Box>
                    }
            </div>
        </BrowserRouter>
    );
};

export default App;
